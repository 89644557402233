/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-04-11",
    versionChannel: "nightly",
    buildDate: "2025-04-11T00:06:52.070Z",
    commitHash: "4a5ee36e6371d3f349e1f25c0f92ecd34abfd493",
};
